.location-detail .detail-header {
    display: flex;
    justify-content: space-between;
    color: #bfd1d9;
    font-size: 14px;
}

.location-detail .detail-header .location-name {
    font-weight: bold;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.location-detail .detail-header .location-name .back-icon {
    cursor: pointer;
}

.location-detail .location-address {
    display: flex;
    font-size: 14px;
    margin: 20px 0 20px -5px;
}

.location-detail .location-address .address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.location-detail .store-timing .panel1-accordion {
    box-shadow: none !Important;
    margin-top: -15px !important;
    background-image: linear-gradient(to right, #081922 91%, rgba(50, 68, 86, 1) 1%);
    background-position: bottom;
    background-size: 24px 1px;
    background-repeat: repeat-x;
    justify-content: space-between;
}

.location-detail .store-timing .panel1-accordion .panel1-accordion-detail {
    font-size: 13px !important;
    width: 270px !important;
}

.location-detail .store-timing .panel1-accordion .panel1-accordion-detail .time-row {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-left: 16px;
}

.location-detail .store-timing .panel1-accordion .panel1-accordion-detail .active {
    color: #bfd1d9;
}

.location-detail .products-section {
    font-size: 13px !important;
    width: 270px !important;
}

.location-detail .product-title {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
    display: flex;
}

.location-detail .products-section .product-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.location-detail .products-section .product-row .main-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.location-detail .products-section .product-row .status {
    /*color: #58e0d0 !important;*/
}

.location-detail .stock-query-title {
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
}

.location-detail .stock-query-desc {
    font-size: 12px;
    display: flex;
    margin-top: 5px;
}


.location-detail .form .control {
    display: flex;
    width: 270px !important;
    margin: 20px 0 20px -2px;
}

.location-detail .form .control input {
    border-radius: 20px;
    padding: 0 15px;
    color: #c0d2da !important;
    height: 40px;
    width: inherit;
    font-size: 12px;
}

.location-detail .form .control textarea {
    border-radius: 20px;
    padding: 3px 15px;
    color: #c0d2da !important;
    font-size: 12px;
    width: inherit;
}

.location-detail .form .control input::placeholder {
    color: #c0d2da !important;
    opacity: 0.5;
}

.location-detail .submission {
    display: flex;
}

.location-detail .submission button {
    width: 90%;
    border-radius: 20px;
    font-weight: bold
}

.location-detail .submission .keep-updated {
    margin: 25px 0 20px 0;
    display: flex;
}

.location-detail .submission .keep-updated label {
    font-size: 12px;
}

.location-detail .submission .keep-updated .stock-query-checkbox {
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 15px;
    margin: 4px;
    cursor: pointer;
}

.location-detail .submission .keep-updated .box {
    background: #58e0d0;
    width: 5px;
    height: 5px;
    border-radius: 15px;
    position: relative;
    left: -13px;
    top: 8px;
}

.location-detail .submission .keep-updated .empty {
    width: 5px;
    height: 5px;
}

.location-detail .feedback-message {
    margin: 10px;
    font-size: 14px;
}
