.App {
  text-align: center;
  height: 100vh;
  box-shadow: none;
  overflow: hidden;
}

.map-Div {
  height: 100%;
}
.leaflet-bar {
  display: none !important;
}
.leaflet-bottom {
  display: none;
}
.left-right-wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 3px;
  height: 100%;
}
.marker-cluster-custom {
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  height: 20px;
  width: 20px;
  font-size: 12px;
  font-weight: bolder;
  color: #fff;
}
.single-figure {
  width: 30px !important;
  height: 30px !important;
}

.single-figure > span {
  margin-top: -5px !important;
}
.double-figure {
  width: 30px!important;
  height: 30px !important;
}
.triple-figure {
  width: 40px !important;
  height: 40px !important;
  padding: 5px 0;
}
.four-figure {
  width: 50px !important;
  height: 50px !important;
  padding: 10px 0;
}
.green {
  background: #59e0d0;
}
.even {
  background: #000000;
  color: #fff;
}
.nextleaf {
  background: #FEBD2F;
  color: #fff;
}
.blue {
  background: #1d67f9;
  color: #fff;
}
.brown {
  background: #795548;
  color: #fff;
}
.olive {
  background: #77815b;
  color: #fff
}
.purple{
  background: #764199;
  color: #fff;
}
.ghost{
  background: #EB1C74;
  color: #fff;
}
.manna{
  background: #005b4c;
  color: #fff;
}
.volo{
  background: #9875FF;
  color: #fff;
}
.zire{
  background: #de1582;
  color: #fff;
}
.woodynelson{
  background: #234230;
  color: #fff;
}
.collectiveproject{
  background: #01B4AD;
  color: #fff;
}
.northriver{
  background: #345F71;
  color: #fff;
}
.orchid{
  background: #C6007e;
  color: #fff;
}
.tribal{
  background: #234230;
  color: #fff;
}
.nugz{
  background: #FFCF23;
  color: #fff;
}
.grey {
  background: #7b8ca0 !important;
  margin-left: -11px !important;
}

.disable {
  border: 9px solid #333333 !important;
  color: #515151 !important;
}

.marker-cluster-custom > span {
  display: block;
  border-radius: 50%;
}
.selected-marker {
  border-radius: 50%;
  color: #08161c;
  line-height: 30px;
  text-align: center;
  height: 20px !important;
  width: 20px !important;
  font-size: 12px;
  font-weight: bolder;
}
.current-location-custom-marker-sense > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #962e8a;
  -moz-box-shadow: 0px 0px 25px 20px #962e8a;
  -webkit-box-shadow: 0px 0px 25px 20px #962e8a;
}

.current-location-custom-marker-orchid > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #c6007e;
  -moz-box-shadow: 0px 0px 25px 20px #c6007e;
  -webkit-box-shadow: 0px 0px 25px 20px #c6007e;
}

.current-location-custom-marker-nugz > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #836A4D;
  -moz-box-shadow: 0px 0px 25px 20px #836A4D;
  -webkit-box-shadow: 0px 0px 25px 20px #836A4D;
}

.marker-cluster-custom.purple > span {
  display: block;
  border-radius: 50%;
}
.disabled-location > span {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  background: black;
  margin: 6px;
}
.current-location-custom-marker-ghostdrops > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #EA1A77;
  -moz-box-shadow: 0px 0px 25px 20px #EA1A77;
  -webkit-box-shadow: 0px 0px 25px 20px #EA1A77;
}
.current-location-custom-marker-volo > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #9875FF;
  -moz-box-shadow: 0px 0px 25px 20px #9875FF;
  -webkit-box-shadow: 0px 0px 25px 20px #9875FF;
}
.current-location-custom-marker-manna > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #005b4c;
  -moz-box-shadow: 0px 0px 25px 20px #005b4c;
  -webkit-box-shadow: 0px 0px 25px 20px #005b4c;
}
.current-location-custom-marker-woodynelson > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #234230;
  -moz-box-shadow: 0px 0px 25px 20px #234230;
  -webkit-box-shadow: 0px 0px 25px 20px #234230;
}
.current-location-custom-marker-collectiveproject{
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #01B4AD ;
  -moz-box-shadow: 0px 0px 25px 20px #01B4AD ;
  -webkit-box-shadow: 0px 0px 25px 20px #01B4AD ;
}

.current-location-custom-marker-northriver > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #345f71;
  -moz-box-shadow: 0px 0px 25px 20px #345f71;
  -webkit-box-shadow: 0px 0px 25px 20px #345f71;
}
.current-location-custom-marker > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #59e1d0;
  -moz-box-shadow: 0px 0px 25px 20px #59e1d0;
  -webkit-box-shadow: 0px 0px 25px 20px #59e1d0;
}

.current-location-custom-marker-farma > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #795548;
  -moz-box-shadow: 0px 0px 25px 20px #795548;
  -webkit-box-shadow: 0px 0px 25px 20px #795548;
}

.current-location-custom-marker-dark > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #1e67f9;
  -moz-box-shadow: 0px 0px 25px 20px #1e67f9;
  -webkit-box-shadow: 0px 0px 25px 20px #1e67f9;
}

.current-location-custom-marker-premium > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #ea1a77;
  -moz-box-shadow: 0px 0px 25px 20px #ea1a77;
  -webkit-box-shadow: 0px 0px 25px 20px #ea1a77;
}

.current-location-custom-marker-olive > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #77815b;
  -moz-box-shadow: 0px 0px 25px 20px #77815b;
  -webkit-box-shadow: 0px 0px 25px 20px #77815b;
}


.current-location-custom-marker-even > span {
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background: white;
  margin: 17px;
  opacity: 1;
  box-shadow: 0px 0px 25px 20px #000000;
  -moz-box-shadow: 0px 0px 25px 20px #000000;
  -webkit-box-shadow: 0px 0px 25px 20px #000000;
}

.location-direction .leaflet-popup-content-wrapper {
  background-color: black;
  border-radius: 0;
  color: white;
}
hr {
  margin-top: 0rem;
  margin-bottom: 0rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-color: white;
}
.header {
  margin-bottom: 0;
  font-weight: bold;
  margin-top: -10px;
}
.address-section {
  margin: 5px 0;
}
.address-section > .bld-name {
  font-weight: bold;
}
.address-section > .secondary-txt {
  color: #999999;
}

/*.location-direction .leaflet-popup-content-wrapper,*/
/*.leaflet-popup-tip {*/
/*  background: #08161c !important;*/
/*}*/

.leaflet-popup {
  margin-left: -20px;
}

.dark-mode .leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #08161c !important;
  color: #bfd1d9 !important;
}

.white-mode .leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #f2f2f2 !important;
  color: #222222 !important;
}

.white-mode {
  background: #f2f2f2 !important;
  color: #222222 !important;
}

.dark-mode {
  background: #08161c !important;
  color: #bfd1d9 !important;
}

.location-direction .leaflet-container a.leaflet-popup-close-button {
  color: white !important;
}
.outer-div-direction {
  margin-top: 6px;
}
.direction-popup-button {
  margin: 16px 0 10px 0;
  padding: 2px 24px;
  font-weight: 600;
  text-decoration: none !important;
}
.date-updated {
  margin-top: 4px;
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
}
.location-direction.leaflet-tooltip {
  background: black;
  color: white;
  text-align: left;
  border: none;
  border-radius: 5px;
}
.location-direction.leaflet-tooltip-right:before {
  border-right-color: black !important;
}
.slide-pane__overlay.overlay-after-open {
  z-index: 9999;
}
.slide-pane__overlay.overlay-after-open > .slide-pane {
  height: 40vh !important;
  margin-top: 60vh !important;
}
.slide-pane__close {
  position: absolute;
  top: -30px;
  left: 40%;
  width: 20%;
  background: #fff;
  opacity: 1 !important;
  height: 30px;
  margin-left: 0 !important;
  padding: 0 !important;
}
.slide-pane__close svg {
  font-size: 40px;
  margin-left: 22%;
  padding-bottom: 5px !important;
  width: 40px !important;
}
.slide-pane__header {
  flex: 0 0 0px !important;
  background-color: black !important;
}
.slide-pane__content {
  background-color: black !important;
  color: #fff !important;
}
.slide-pane__content hr {
  width: calc(100% + 67px);
  margin-left: -35px;
}
.MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1);
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 4px !important;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 4.5px 4px !important;
}
.MuiOutlinedInput-root {
  border-radius: 0px !important;
  border: none !important;
  outline: none !important;
}
.MuiOutlinedInput-root:focus,
.MuiFormControl-fullWidth:focus,
.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon:focus {
  outline: none !important;
  border: none !important;
}
.MuiAutocomplete-listbox {
  padding: 2px 0 !important;
}
.react-responsive-modal-modal {
  width: 450px;
  border-radius: 5px;
  z-index: 9999;
}
@media (max-width: 767px) {
  .react-responsive-modal-modal {
    width: 250px !important;
  }
}
.btn-ok {
  box-shadow: none;
  float: right;
  border-radius: 5px;
  width: 20%;
  font-size: 14px;
}
.react-responsive-modal-closeButton {
  top: 8px !important;
  right: 8px !important;
}
.react-responsive-modal-closeButton svg {
  fill: #fff !important;
  width: 15px;
  height: 15px;
  margin: 10px 3px;
}
.location-request {
  padding: 10px 10px 10px 0;
  margin-bottom: 0;
  font-size: 14px;
}
.modal-heading {
  font-size: 16px;
}
.react-responsive-modal-root {
  z-index: 9999 !important;
}
.alert-error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
