@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?7568762f619b7931d225fbb998aedbd4") format("truetype"),
url("./flaticon.woff?7568762f619b7931d225fbb998aedbd4") format("woff"),
url("./flaticon.woff2?7568762f619b7931d225fbb998aedbd4") format("woff2"),
url("./flaticon.eot?7568762f619b7931d225fbb998aedbd4#iefix") format("embedded-opentype"),
url("./flaticon.svg?7568762f619b7931d225fbb998aedbd4#flaticon") format("svg");
}


i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-gps:before {
    content: "\f101";
}
.flaticon-n:before {
    content: "\f102";
}
.flaticon-relaxing-walk:before {
    content: "\f103";
}
.flaticon-running-stick-figure:before {
    content: "\f104";
}
