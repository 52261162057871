.slider-Zoom {
  position: relative;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.slider-Zoom .MuiSlider-root {
  color: white;
}
.slider-Zoom .MuiSlider-rail {
  width: 2px !important;
  opacity: 1 !important;
  background: #0c212c;
}
.slider-Zoom .MuiSlider-track {
  width: 2px !important;
  background: #0c212c;
}
.slider-Zoom .MuiSlider-thumb {
  width: 0;
  height: 0;
  color: #59e0d0;
  margin-left: -6.5px !important;
  margin-top: -11px !important;
  margin-bottom: -14px !important;
}
.blue-neobi .MuiSlider-thumb::after {
  top: -37px !important;
  left: 0 !important;
  right: -15px !important;
  bottom: -15px !important;
  background-image: url('../../images/slider-sm.png');
  background-repeat: no-repeat;
  height: 100px;
}

.white-neobi .MuiSlider-thumb::after {
  top: -37px !important;
  left: 0 !important;
  right: -15px !important;
  bottom: -15px !important;
  background-image: url('../../images/slider-sm-light.png');
  background-repeat: no-repeat;
  height: 100px;
}

.farma-neobi .MuiSlider-thumb::after {
  top: -37px !important;
  left: 0 !important;
  right: -15px !important;
  bottom: -15px !important;
  background-image: url('../../images/slider-sm-purefarma.png');
  background-repeat: no-repeat;
  height: 100px;
}

.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
  box-shadow: none !important;
}

.slider-Zoom .logo {
  color: #bfd1d9;
  display: flex;
  align-items: end;
  font-size: 20px;
  margin-bottom: 8px;
}
.slider-Zoom .logo a{
  color: #bfd1d9 !important;
  display: flex;
  align-items: end;
  font-size: 20px;
  text-decoration: none;
}
.logo {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99999;
}
.logo img {
  width: 200px;
  height: 38px;
  margin-bottom: 6px;
  margin-left: 5px;
}
.minus-icon-wrapper{
  margin-bottom: 50px;
  margin-top: 40px;
}
.minus-icon-wrapper .MuiSvgIcon-root {
  color: white;
  font-size: 26px;
  margin-right: -1px;
  margin-top: 0;
}
.plus-icon-wrapper{
  margin-top: 50px;
}
.plus-icon-wrapper .MuiSvgIcon-root {
  color: white;
  font-size: 26px;
  margin-right: -1px;
}
.gps-icon-wrapper {
  color: white;
  display: flex;
  margin-bottom: 50px;
  cursor: pointer;
  font-size: 35px;
}
.gps-icon-wrapper-ios{
  margin-bottom: 120px !important;
}
.kebab-menu-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}
.kebab-menu-wrapper > .n-Icon {
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  font-size: 38px;
  color: white;
}
.n-Direction {
  margin-bottom: -6px;
  font-size: 57px !important;
  color: white;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
}
