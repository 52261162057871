body {
  margin: 0;
  padding: 0;
  /* font-family: ProximaNova-Light!important; */
  touch-action: none;
}

@font-face {
  font-family: "NimbusSan-Reg";
  src:local('NimbusSan-Reg'),url(./assets/fontfamily/NimbusSan-Reg.ttf) format("truetype");
  font-style: normal; 
}
@font-face {
  font-family: "NimbusSan-Bol";
  src:local('NimbusSan-Bol'),url(./assets/fontfamily/NimbusSan-Bol.ttf) format("truetype");
  font-style: normal;
   font-weight: 600;
  
}


* {
  box-sizing: border-box;
}
::-webkit-scrollbar-thumb {
  background: silver;
}
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}
