.MuiAppBar-root {
    height: 65px !important;
}


.MuiBox-root header {
    height: 90px !important;
    box-shadow: none;
}

.upper-nav {
    padding: 5px 5px 0 5px;
}

.upper-nav .img-logo {
    display: flex;
}

.upper-nav .img-logo .avatar .avatar-profile {
    margin-right: 5px;
    width: 30px;
    height: 30px;
}

.upper-nav .img-logo img {
    width: 160px;
    margin: 0 0 -15px -15px;
}

.upper-nav .nav-menu {
    margin-right: 43%;
    margin-top: 15px;
}

.upper-nav .avatar-username {
    height: 38px;
    font-size: 13px;
    align-items: center;
    display: flex;
}

.upper-nav .nav-menu .menu-text {
    font-size: 13px !important;
    margin: 0;
}


.lower-nav {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
}


.lower-nav .nav-search {
    padding: 0 9px 6px 9px;
    width: 100%;
}

.lower-nav .nav-search input::placeholder {
    color: #c0d2da !important;
    font-size: 12px;
    opacity: 0.5;
}

.lower-nav .collapse-icon {
    padding: 10px 5px 0 5px;
    margin-top: -7px;
    cursor: pointer;
}

.lower-nav .nav-search input {
    border-radius: 20px;
    padding: 0 30px;
    font-size: 12px;
    width: 100%;
}

.lower-nav .selected-filter-nav {
    margin-right: auto;
    margin-left: 10px;
    width: 80%;
    overflow: auto;
    display: flex;
}

.lower-nav .selected-filter-nav .filter-tag {
    font-size: 14px;
    font-weight: 400;
    margin-top: 6px;
}

::-webkit-scrollbar {
    height: 3px;
    width: 3px;
}

.menu-wrapper {
    box-shadow: 0 0 1px #000;
    height: 33px;
    width: 33px;
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 10px 0;
}

.custom-drawer .MuiDrawer-paper {
    width: 0 !important;
    /*background-color: #08161c !important;*/
}


.sidebar-menu .sub-menu {
    margin-bottom: 0 !important;
}

.sidebar-menu .sub-menu .box {
    min-width: 8px;
    height: 8px;
    border: 1px solid #324455;
    display: flex;
    align-self: center;
    margin-right: 10px;
    border-radius: 7px;
}

.sub-menu .checked {
    /*background: #59cfc6 !important;*/
    position: relative;
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 20px;
    top: 19.85%;
    left: 18.74%;
}

.sidebar-menu .sub-menu .filter-sub-menu {
    display: flex;
    padding: 10px;
    margin-left: 5px;
}

.filter-sub-menu .filter-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.sub-menu .filter-sub-menu-accordion {
    height: auto;
    max-height: 200px;
    overflow: auto;
    padding: 0 10px !important;
    margin-top: -7px !important;
}

.sidebar-menu .sub-menu .result-sub-menu-accordion {
    width: 100%;
    background: #00324b;
    font-size: 10px;
    color: #9fdbef;
}

.sidebar-menu .sub-menu .result-sub-menu-accordion p {
    margin-bottom: 10px !important;
}

.sidebar-menu .sub-menu .result-sub-menu-accordion .horizontal-line {
    border-top: 1px solid #9fdbef;
    opacity: 0.6;
}

.sidebar-menu .sub-menu .result-sub-menu-accordion .product-section {
    margin-top: 7px;
}

.sidebar-menu .sub-menu .result-sub-menu-accordion .product-section tr {
    margin-top: 5px !important;
}

.sidebar-menu .sub-menu .result-sub-menu-accordion table thead th {
    color: #fff;
    font-weight: bold !important;
    font-size: 12px;
}

.sidebar-menu .sub-menu .result-sub-menu-accordion .product-section .column-spacing {
    padding: 0 15px 0 15px;
}

.height-0 {
    height: 0 !important;
}

.bold {
    font-weight: bold !important;
}

.not-found {
    font-size: 13px;
    padding: 15px;
}

.sidebar-container {
    /*border-top: 5px solid #08161c;*/
    border-right: 5px solid #08161c;
    height: 100vh;
    background: #081922;
    overflow-y: auto;
    margin-top: 90px;
    /*display: flex;*/
    padding: 10px;
    overflow-x: hidden;
}

.sidebar-container .sidebar-list {
    display: block;
    width: 100%;
}

.sidebar-container .sidebar-list .main-menu {
    box-shadow: none;
    background-position: bottom;
    background-size: 24px 1px;
    background-repeat: repeat-x;
    margin: 0;
}
.css-admsbc-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    height: 48px;
}
.css-admsbc-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 50px !important;
}

.sidebar-container .sidebar-list .sub-menu {
    box-shadow: none;
    border-bottom: none
}

.sidebar-container .sidebar-list .sub-menu .sub-menu-detail .MuiAccordionSummary-content {
    align-items: center !important;
}

.sidebar-container .sidebar-list .sidebar-list-item {
    justify-content: space-between;
    box-shadow: none;
    display: flex;
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-size: 13px !important;
    padding: 13px 4px;
    cursor: pointer;
}


.sidebar-container .sidebar-list .sidebar-list-item .near-me-location {
    color: #7f8a92;
    font-size: 11px;
    margin-right: -8px;
    font-weight: normal !important;
}

.sub-menu .filter-sub-menu {
    display: flex;
    padding: 5px 0;
}

.sub-menu .filter-sub-menu .filter-menu-radio {
    align-self: center;
    margin-right: 5px;
}

.sub-menu .box {
    border: 1px solid #324455;
    height: 10px;
    min-width: 10px;
    align-self: center;
    border-radius: 20px;
    margin-right: 5px;
    cursor: pointer;
    /*background: #08161c;*/
}

.result-section {
    margin-top: 10px;
    margin-bottom: 10px;
}

.result-section .result-item {
    font-size: 12px;
    padding: 8px 0 8px 6px;
    cursor: pointer;
}

.result-section .result-item .result-header {
    display: flex;
    justify-content: space-between;
}

.result-section .result-item .result-header .location-name {
    font-weight: bold;
    display: flex;
}

.result-section .result-item .result-header .location-name .text {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.result-section .result-item .result-header .location-name .active-location {
    height: 10px;
    width: 10px;
    align-self: center;
    border-radius: 14px !important;
    margin-right: 5px;
}

.result-section .result-item .result-header .bright-location {
    color: #bfd1d9 !important;
}

.result-section .result-item .result-header .bright-status {
    color: #58e0d0 !important;
}

.result-section .result-item .result-address {
    display: flex;
    justify-content: space-between;
}
.result-section .result-item .result-address .address {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.result-section .result-item .result-address .bright-distance {
    color: #bfd1d9 !important;
}

.result-section .result-item .timing {
    display: flex;
}

.result-section .result-item .website {
    display: flex;
}

.result-section .result-item .website a {
    color: #7b8ca0 !important;
}

.filter-sub-menu-accordion .parent-selected {
    display: flex;
    justify-content: space-between;
    font-size: 13px !important;
    font-weight: bold;
}

.filter-sub-menu-accordion .parent-selected .selected-icon {
    cursor: pointer;
}

.nav-search .suggestion {
    position: absolute;
    max-width: 246px;
    max-height: 300px;
    overflow-y: auto;
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
    width: 246px;
}

.nav-search .suggestion .menu .menu-item {
    padding: 5px;
    display: flex;
}

.nav-search .suggestion .menu .menu-item span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nav-search .suggestion .menu .menu-item:hover {
    background: #ccc;
    cursor: pointer;
}

.nav-search .d-none {
    visibility: hidden;
}
